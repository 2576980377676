import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { Avatar, Backdrop, Box, CircularProgress, Container, Divider, Fab, Fade, Grid, Hidden, IconButton, LinearProgress, Link, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography, Zoom } from '@material-ui/core';
import { Videocam, ArrowBack, Clear, Event, EventAvailable, EventBusy, Print, Search } from '@material-ui/icons';

// import InputMask from 'react-input-mask';

import PDFView from '../../Components/PDF';

import moment from 'moment';

import Service from './../../service';

import { FormatCPF, NumberFormatPhone } from '../../Components/FormatNumber';

import { cpf } from 'cpf-cnpj-validator';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AlertDialog from '../../Components/Confirm';

const api = Service.getInstance();

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    atendido: {
        background: theme.palette.success.main
    },
    naoatendido: {
        background: theme.palette.error.main
    },
    cancelado: {
        background: theme.palette.grey[400]
    },
    legendaCancelado: {
        background: theme.palette.common.white,
        color: theme.palette.error.main
    },
    legendaImprimir: {
        background: theme.palette.common.white,
        color: theme.palette.grey[600]
    },
    padrao: {
        textDecoration: 'none',
    },
    warning: {
        textDecoration: 'line-through solid #000',
        color: theme.palette.error.dark,
        textDecorationColor: theme.palette.error.main,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const schema = yup.object().shape({
    cpf: yup.string().required("O CPF é obrigatório"),
    fone: yup.string().required("Informe o número do celular").min(11, 'Deve conter 11 dígitos')
});

export default function StickyHeadTable() {

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = React.useState(false);
    const [spine, setSpine] = React.useState(false);

    const [agendamento, setAgendamento] = React.useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const buscar = React.useCallback((data) => {

        if (cpf.isValid(data.cpf)) {

            setLoading(true)

            api.get(`/atendimentos?cpf=${data.cpf}&fone=${data.fone}`, data).then((response) => {

                setLoading(false)

                setAgendamento(response.data.data)

            }).catch(error => {

                console.log(error);

                // const { response } = error;

                // switch (response.status) {
                //     case 500:
                //         enqueueSnackbar(response.data, { variant: "error" });
                //         break;
                //     case 404:
                //         enqueueSnackbar('Não foram encontrado agendamentos para os registros informados!', { variant: "error" });
                //         break;
                //     default:
                //         enqueueSnackbar('Erro não identificado. Tente novamente!', { variant: "error" });
                // }

                setLoading(false)
                setAgendamento([]);

            })

        } else {
            setAgendamento([]);
            setLoading(false)
            enqueueSnackbar('CPF inválido', { variant: 'warning' });
        }

    }, [enqueueSnackbar]);

    const formatData = (data) => {
        if (data)
            return moment(data).format('DD.MM.YYYY');
    }

    const formatHora = (hora) => {
        if (hora)
            return hora.substring(0, hora.length - 3)
    }

    const cancelar = async (id) => {

        setSpine(true);

        await api.delete(`atendimentos/${id}`).then(response => {
            
            enqueueSnackbar('Agendamento deletado com sucesso!', { variant: 'success' });

            setAgendamento(atendimento => atendimento.filter(item => item.id !== id));

        }).catch(error => {

            setSpine(false);

            const { data: { message } } = error.response;

            if (message !== undefined) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return;
            }

            enqueueSnackbar('Erro inesperado. Tente novamente!', { variant: 'error' });

        });

        setSpine(false);

        // await api.get(`cancelar/${id}`)

        //     .then(res => {

        //         enqueueSnackbar('Agendamento cancelado com sucesso!', { variant: 'success' });

        //         const { data } = res.data;
                
        //         setAgendamento(agendamento.map((item) => (item.id === data.id ? data : item)));

        //         console.log(data);

        //         setSpine(false);

        //     }).catch(error => {

        //         setSpine(false);

        //         const { data: { message } } = error.response;

        //         if (message !== undefined) {
        //             enqueueSnackbar(error.response.data.message, { variant: 'error' });
        //             return;
        //         }

        //         enqueueSnackbar('Erro inesperado. Tente novamente!', { variant: 'error' });


        //     })
    }

    return (
        <Fade in={true} timeout={700}>
            <Container maxWidth="lg">
                <Backdrop className={classes.backdrop} open={spine}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Paper>
                    <div>
                        <form onSubmit={handleSubmit(buscar)}>
                            <Box display="flex" justifyContent="flex-end" ml={3} mr={1}>
                                <Box mt={4} mr={1}>
                                    <TextField
                                        id="cpf"
                                        variant="standard"
                                        fullWidth
                                        placeholder="CPF"
                                        autoFocus
                                        {...register("cpf")}
                                        helperText={errors.cpf?.message}
                                        error={errors.cpf !== undefined}
                                        InputProps={{
                                            className: classes.searchInput,
                                            inputComponent: FormatCPF,
                                        }}
                                    />
                                </Box>
                                {/* <Box mt={4}>
                                <InputMask mask="****-****-****-****" {...register("protocolo")}>
                                    {(inputProps) => <TextField
                                        
                                        {...inputProps}
                                        variant="standard"
                                        fullWidth
                                        placeholder="Protocolo"
                                        helperText={errors.protocolo?.message}
                                        error={errors.protocolo !== undefined}
                                    />}
                                </InputMask>
                            </Box> */}
                                <Box mt={4}>
                                    <TextField
                                        id="fone"
                                        variant="standard"
                                        fullWidth
                                        placeholder="Celular"
                                        {...register("fone")}
                                        helperText={errors.fone?.message}
                                        error={errors.fone !== undefined}
                                        InputProps={{
                                            className: classes.searchInput,
                                            inputComponent: NumberFormatPhone,
                                        }}
                                    />
                                </Box>
                                <Box mt={4}>
                                    <IconButton type="submit" color="default">
                                        <Search size="small" />
                                    </IconButton>
                                </Box>
                            </Box>

                        </form>
                    </div>
                    <Divider style={{ marginTop: 20 }} />
                    {loading ? <LinearProgress /> : ''}

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow >
                                    <TableCell style={{width: '5%'}}></TableCell>
                                    <TableCell style={{width: '20%'}}>Instituição</TableCell>
                                    <TableCell style={{width: '30%'}}>Serviço solicitado</TableCell>
                                    <TableCell style={{width: '10%'}}>Protocolo/Data/Hora</TableCell>
                                    <TableCell style={{width: '5%'}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agendamento.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={key} className={Boolean(row.cancelado) === true ? classes.warning : classes.padrao} >
                                            <TableCell>
                                                {Boolean(row.cancelado) === true ? <Avatar className={classes.cancelado}>
                                                    <EventBusy />
                                                </Avatar> : Boolean(row.status) === true ?
                                                    <Avatar className={classes.atendido}><EventAvailable /></Avatar> :
                                                    <Avatar className={classes.naoatendido}><Event /></Avatar>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex-inline">
                                                    <Box>{row.agendamento.servico.unidade.descricao}</Box>
                                                    <Box><Typography component="div"><Box fontSize={11}>{`${row.agendamento.servico.unidade.logradouro}, ${row.agendamento.servico.unidade.numero}, ${row.agendamento.servico.unidade.bairro}`}</Box></Typography></Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex-inline">
                                                    <Box>{row.agendamento.servico.descricao}</Box>
                                                    

                                                    <FadeObservacao obs={row} />


                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex-inline">
                                                    <Box>{row.protocolo}</Box>
                                                    <Box>
                                                        <Typography component="div">
                                                            <Box fontSize={12}>{formatData(row.agendamento.data)} {formatHora(row.agendamento.hora)}</Box>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex">
                                                    <Box>
                                                        {row.cancelado === false && row.status === false ?
                                                            <AlertDialog deletar={() => cancelar(row.id)} /> : ''}
                                                    </Box>
                                                    <Box component="a" href={`https://agendamento.digitaleducar.com.br/webapi/atendimento-online/${row.id}`} target="_blank"><IconButton><Videocam color="secondary" /></IconButton></Box>
                                                    <Hidden xsDown>
                                                        <Box>
                                                            {row.cancelado === false && row.status === false ? <IconButton onClick={() => PDFView(row)} color="default"><Print /></IconButton> : ''}
                                                        </Box>
                                                    </Hidden>
                                                </Box>

                                                {/* {agendamento.status === false ? <Zoom in={true}><Tooltip title="Imprimir" placement="top-start"><Fab onClick={() => PDFView(agendamento)} size="small" color="primary" aria-label="print"><Print/></Fab></Tooltip></Zoom> : '' } */}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={agendamento.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />

                    <Box display="flex" alignItems="center">
                        <Box display="flex" flexDirection="row" p={3}>
                            <Box mr={1}>
                                <Zoom in={true}><Fab size="small" aria-label="edit" color="primary" onClick={() => history.push('/')}><ArrowBack /></Fab></Zoom>
                            </Box>
                            <Box>
                                <Hidden xsDown>
                                    {agendamento.status === false ? <Zoom in={true}><Tooltip title="Imprimir" placement="top-start"><Fab onClick={() => PDFView(agendamento)} size="small" color="primary" aria-label="print"><Print /></Fab></Tooltip></Zoom> : ''}
                                </Hidden>
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box pl={3} pb={1} pt={1}>
                        <Grid container className={classes.contentWrapper}>
                            <Grid container item>
                                <Typography variant="caption">Legenda:</Typography>
                            </Grid>
                            <Grid item lg={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Box p={1}>
                                        <Avatar className={classes.atendido}>
                                            <EventAvailable />
                                        </Avatar>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Atendido</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Box p={1}>
                                        <Avatar className={classes.naoatendido}>
                                            <Event />
                                        </Avatar>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Não atendido</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Box p={1}>
                                        <Avatar className={classes.cancelado}>
                                            <EventBusy />
                                        </Avatar>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Cancelado</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Box p={1}>
                                        <Avatar className={classes.legendaCancelado}>
                                            <Clear />
                                        </Avatar>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Cancelar agendamento</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} md={6}>
                                <Box display="flex" alignItems="center">
                                    <Box p={1}>
                                        <Avatar className={classes.legendaImprimir}>
                                            <Print />
                                        </Avatar>
                                    </Box>
                                    <Box>
                                        <Typography variant="caption">Imprimir comprovante</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Fade>
    );
}


function FadeObservacao({obs}){

    const [readMore, setReadMore] = React.useState(false);

    return (
        <Box><Typography component="div">
            {obs.agendamento.servico.observacoes && <Box fontSize={11}>
                {readMore ? obs.agendamento.servico.observacoes : `${obs.agendamento.servico.observacoes.substring(0, 50)}...`}
                <Link onClick={() => setReadMore(!readMore)} style={{ cursor: 'pointer', fontWeight: 600 }}>
                    {readMore ? ' mostrar menos' : '  expandir'}
                </Link>
            </Box>}
        </Typography></Box>
    )
}
